import React from 'react'
import { graphql } from 'gatsby'

import Digest from '../components/digest/digest'

const Olivia = ({ location, data }) => {
  if (typeof window !== 'undefined') localStorage.setItem('origin', 'olivia')
  return <Digest location={location} data={data} />
}

export default Olivia

export const query = graphql`
  {
    allContentfulDigest(filter: {name: {name: {eq: "Olivia Ruiz-Knott"}}}) {
      edges {
        node {
          id
          name {
            name
            email
            github
            futureland
          }
          title
          summary {
            id
            childMarkdownRemark {
              html
            }
          }
          projects {
            id
            title
            slug
            description {
              description
            }
            when
          }
          workExperience {
            id
            position
            organization
            startDate
            endDate
            location
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          educationExperience {
            id
            institution
            degree
            startDate
            endDate
            location
          }
        }
      }
    }
  }
`
