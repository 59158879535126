import React from 'react'
import { Link } from 'gatsby'

import DeckSerif from '../headers/deck-serif'
import SubtitleSans from '../headers/subtitle-sans'

import PastIcon from '../../icons/time-codes/past.svg'
import NowIcon from '../../icons/time-codes/now.svg'
import FutureIcon from '../../icons/time-codes/future.svg'
import RecurringIcon from '../../icons/time-codes/recurring.svg'

const iconForTime = {
  'Past': PastIcon,
  'Now': NowIcon,
  'Future': FutureIcon,
  'Recurring': RecurringIcon,
}

export default ({ project, theme2, includeOwner }) => {
  const borderTheme = theme2 ? "border-theme2-secondary" : "border-theme1-secondary"
  const icon = iconForTime[project.when]

  return (
    <Link to={`/projects/${project.slug}`}>
      <div className={`project-preview-list border-t ${borderTheme} flex py-2`}>
        { includeOwner && project.owner &&
          <div className="grow-0 shrink-0 w-4 mr-2 md:mr-6">
            <div className={`header-sans font-semibold font-sans text-theme1-secondary text-2xl leading-7 md:text-3xl`}>{project.owner}</div>
          </div>
        }
        <div className="grow mr-4 md:mr-8">
          <DeckSerif text={project.title} theme2={theme2} />
          <SubtitleSans text={project.description.description} theme2={theme2} />
        </div>
        <div className="grow-0 shrink-0 w-12 md:w-16 h-8 flex justify-end items-middle">
          <img src={icon} alt={project.when} />
        </div>
      </div>
    </Link>
  )
}
