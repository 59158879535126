import React from 'react'
import moment from 'moment';

import DeckSerif from '../headers/deck-serif'
import MetaSans from '../headers/meta-sans'

export default ({ experiences, type }) => {
  return (
    <div className="experience-listing">
      {
        experiences.map(( experience ) => {
          let deck, meta
          switch (type) {
            case "work":
              deck = experience.position
              meta = experience.organization
              break
            case "education":
              deck = experience.institution
              meta = experience.degree
              break
            default:
              deck = experience.deck
              meta = experience.meta
          }

          // dates
          const startDate = moment(experience.startDate).format('MMMM YYYY')
          const endDate = experience.endDate ? moment(experience.endDate).format('MMMM YYYY') : 'Present'
          const dateString = `${startDate} – ${endDate}`

          return (
            <div key={experience.id} className="border-t border-theme1-secondary py-4">
              {deck && <DeckSerif text={deck} />}
              <div className="py-2">
                {meta && <MetaSans text={meta} caps={true} />}
                {dateString && <MetaSans text={dateString} caps={true} />}
                {experience.location && <MetaSans text={experience.location} caps={true} />}
              </div>
              {experience.body &&
                <div
                  className="md:text-2xl"
                  dangerouslySetInnerHTML={{
                    __html: experience.body.childMarkdownRemark.html,
                  }}
                />
              }
            </div>
          )
        })
      }
    </div>
  )
}
