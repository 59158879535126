import React, { useState } from 'react'

import { VIEW } from '../../constants'
import ProjectPreviewList from './project-preview-list'

export default ({ projects, theme2, includeOwner }) => {
  const [view] = useState(VIEW.LIST)

  const renderProjects = () => {
    if (view === VIEW.LIST) {
      return projects.map((project) => {
        return (
          <ProjectPreviewList
            key={project.id}
            project={project}
            theme2={theme2}
            includeOwner={includeOwner}
          />
        )
      })
    }

    // handle this later
    if (view === VIEW.GRID) {
      return
    }
  }

  return (
    <div className="project-listing">
      {renderProjects()}
    </div>
  )
}
