import React from 'react'
import get from 'lodash/get'

import HelmetWrapper from '../helmet-wrapper'
import Layout from '../layout'
import AlternateNavigation from '../alternate-navigation'
import DigestSection from '../digest/digest-section'
import ProjectListing from '../projects/project-listing'
import ExperienceListing from '../digest/experience-listing'

export default ({ location, data }) => {
  const digest = get(data, 'allContentfulDigest.edges[0].node')
  const projects = get(digest, 'projects')
  const workExperiences = get(digest, 'workExperience')
  const educationExperiences = get(digest, 'educationExperience')

  const email = digest.name.email || null
  const linkedIn = digest.name.linkedIn || null
  const futureland = digest.name.futureland || null

  const renderAnchorNavigation = () => {
    const links = []
    if (projects) links.push({ href: '#projects', text: 'Projects' })
    if (workExperiences) links.push({ href: '#experience', text: 'Experience' })
    if (educationExperiences) links.push({ href: '#education', text: 'Education' })

    if (links.length < 2) return

    return <AlternateNavigation links={links} />
  }

  return (
    <Layout location={location} navigation={false}>
      <div className="digest">
        <HelmetWrapper pageTitle={digest.name.name} />
        <div className="bg-theme2-bg-1">
          <div className="flex flex-col min-h-1/3 justify-end p-4 md:px-0 md:max-w-2xl md:mx-auto">
            <h1 className="font-serif text-theme2-accent text-4xl">
              {digest.name.name}
            </h1>
            <div className="font-sans font-thin text-theme2-primary text-3xl">
              {digest.title}
            </div>
          </div>
        </div>
        {renderAnchorNavigation()}
        <div className="bg-theme2-bg-1 p-4">
          <div className="md:max-w-2xl md:mx-auto">
            <div
              className="digest-summary font-serif-2 text-theme2-primary text-xl md:text-2xl"
              dangerouslySetInnerHTML={{
                __html: digest.summary.childMarkdownRemark.html,
              }}
            />
            <div className="font-sans text-theme2-accent md:flex">
              {email && <a className="block m-2 ml-0 md:mr-4 font-bold text-xs md:text-lg uppercase" href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">Email</a>}
              {linkedIn && <a className="block m-2 ml-0 md:mr-4 font-bold text-xs md:text-lg uppercase" href={linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn</a>}
              {futureland && <a className="block m-2 ml-0 md:mr-4 font-bold text-xs md:text-lg uppercase" href={futureland} target="_blank" rel="noopener noreferrer">Futureland</a>}
            </div>
          </div>
        </div>
        <div className="bg-theme1-bg-1 m-4">
          <div className="md:max-w-2xl md:mx-auto md:mt-12">
            {
              projects &&
              <DigestSection sectionTitle='Selected Projects' id='projects'>
                <ProjectListing projects={projects} />
              </DigestSection>
            }
            {
              workExperiences &&
              <DigestSection sectionTitle='Experience' id='experience'>
                <ExperienceListing experiences={workExperiences} type='work' />
              </DigestSection>
            }
            {
              educationExperiences &&
              <DigestSection sectionTitle='Education' id='education'>
                <ExperienceListing experiences={educationExperiences} type ='education' />
              </DigestSection>
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}
