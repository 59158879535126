import React from 'react'

export default ({ links }) => {
  return (
    <nav role="navigation" className="bg-theme2-bg-1 p-4 sticky top-0 z-10">
      <div className="md:max-w-2xl md:mx-auto">
        {
          links.map((link) => {
            return (
              <a
                key={link.href}
                href={link.href}
                className='font-bold font-sans mr-4 text-theme2-primary text-sm border-theme2-accent tracking-wider uppercase border-b-2 border-theme2-accent'
              >
                {link.text}
              </a>)
          })
        }
      </div>
    </nav>
  )
}
